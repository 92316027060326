import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  Radio,
  Row,
  Typography,
  Upload,
  Checkbox,
  Select,
  message,
  Modal
} from "antd";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CalendarIcon } from "./assets/calendar-icon.svg";
import { ReactComponent as SoundIcon } from "./assets/sound-icon.svg";
import { ReactComponent as VideoIcon } from "./assets/video-icon.svg";
import { ReactComponent as DropdownIcon } from "./assets/dropdown-icon.svg";
import { ReactComponent as UploadIcon } from "./assets/upload-icon.svg";
import { useState } from "react";

const { Title, Text } = Typography;

const UploadButton = ({ text }: { text: string }) => (
  <div className="border border-dashed border-[#D9D9D9] rounded-[4px] p-2 w-full">
    <div className="flex items-center justify-between gap-2 text-[#1677FF] cursor-pointer">
      <span className="!text-[15px]">{text}</span>
      <UploadIcon />
    </div>
  </div>
);

interface ExampleItem {
  id: number;
  title: string;
  content: string;
}

interface ExampleList {
  public: ExampleItem[];
  family: ExampleItem[];
  farewell: ExampleItem[];
}

const exampleList: ExampleList = {
  public: [
    {
      id: 1,
      title: "範例一",
      content: "各位親愛的家人、朋友，以及今天特地前來送別我的親朋好友們：\n\n此刻，我站在另一個世界，用另一種方式，靜靜地注視著你們。看到你們的臉龐，看到你們的淚水，我的心中滿懷感激，也帶著些許不捨。\n\n回首我的一生，雖然不能說完美無缺，但我為曾經擁有的一切感到無比幸福。謝謝我的家人，無論順境還是逆境，始終陪伴在我的身邊，給予我無盡的支持與愛。謝謝我的朋友，曾與我共享歡笑與淚水，豐富了我的人生。\n\n今天，我要向你們道一聲抱歉。我的離去，讓你們感到傷心和失落。但請相信，這並不是永別，而是一場生命的輪迴。從今以後，我會化作一縷清風、一抹陽光，伴隨在你們左右。當你們仰望星空，或感受到微風拂面時，請記得，那是我在守護著你們。\n\n我最大的心願，是看到你們繼續用笑容迎接每一天。生命的價值不在於長短，而在於珍惜當下，與所愛之人共享美好的時光。所以，請為我擦乾眼淚，繼續前行。把我留下的回憶藏在心底，讓我們的故事成為你們前行的力量。\n\n最後，請允許我說一聲「謝謝」。謝謝你們為我所做的一切，謝謝你們來到這裡送我最後一程。我已經準備好踏上新的旅途，而你們的愛，將永遠陪伴我。\n\n珍重再見，願我們有緣在另一個世界再聚首。"
    },
    {
      id: 2,
      title: "範例二",
      content: "你們大家好 我是XXX\n也是吳帥佑吳美女的超級老媽\n更是吳老爸的妻子\n\n各位親友，感謝你們今天特地來到這裡，送我最後一程。\n請不要為我流太多眼淚，因為我現在已經沒有病痛了。\n我會在天上保佑你們大家\n未來你們一定要互相照顧並且平安 健康!\n\n我的這一生，有家人和你們的陪伴，已經非常幸福了。\n這47年來，我們一起經歷了很多酸甜苦辣，攜手建立了一個完整而幸福的家。謝謝你這麼多年來的包容與陪伴，讓我的生命充滿了愛與溫暖。\n\n現在我離開了，你一定要好好照顧自己，別太累，也別讓自己過得太苦。\n日子還長，記得替我多看看這個世界，好好陪伴我們的孩子們，\n幫我照顧好這個家。"
    }
  ],
  family: [
    {
      id: 1,
      title: "範例一",
      content: "親愛的家人，\n\n今天，我站在另一個世界，用靈魂的聲音，向你們訴說我的思念與感謝。\n\n(母親)，從我來到這個世界的那一刻起，你就給了我無私的愛。你總是默默地為我付出，無論我做了什麼，你都包容我、支持我。媽媽，請不要太過悲傷，你的愛已經深深烙印在我的靈魂裡，我會帶著這份愛繼續前行。未來的日子裡，請好好照顧自己，別再為我勞累了。\n\n(父親)，你的肩膀曾是我依靠的港灣，你的教誨讓我成長為一個有擔當的人。謝謝你總是以沉穩的力量守護著我們的家。爸爸，你是我的榜樣，我知道你會堅強面對一切。現在，家裡的一切就交給你了，我相信你一定能帶著大家走出陰霾。\n\n(兄弟姐妹)，你是我最牽掛的家人之一。從小到大，你都是我心中那個充滿笑容的小女孩。哥沒能陪你走更長的路，真的很抱歉，但我希望你知道，無論你在哪裡，我都會是你的守護星，默默地注視著你。要勇敢，要快樂，把我們未完成的夢想繼續延續下去，好嗎？\n\n(感情伴侶)，謝謝你走進我的生命，讓我體會到了愛情的美好。雖然我們的故事比預期短暫，但每一段回憶都珍貴無比。我希望你能記住我們在一起的美好，而不是沉浸在離別的痛苦中。我會在另一個地方為你祈福，希望你能遇見屬於你的幸福，繼續勇敢地愛與被愛。\n\n我親愛的家人與愛人，請相信，雖然我的身體已離開，但我的心從未遠去。我會以另一種形式，繼續守護你們。當陽光灑下，微風輕拂，那是我在輕聲告訴你們：「一切都會好起來。」\n\n好好地活下去，好好地愛彼此。我們總會有再次重逢的一天。\n\n永遠愛你們的XXXXX"
    },
    {
      id: 2,
      title: "範例二",
      content: "老公啊，47年的感情早就刻在我們的生命裡了，\n我雖然不在，但這份牽掛永遠不會離開你。\n以後的日子，你會發現，我還是以另一種方式陪著你，守著這個家\n現在沒辦法在家裡和你無厘頭(國語)開玩笑了，\n也沒辦法繼續當你的小和事佬，\n你一定要堅強振作起來， 記得自己的身體一定要顧好，別讓我擔心了。\n\n\n兒子啊，媽媽這輩子最驕傲的，就是看著你成長，\n變成一個懂事、有擔當的男人。\n雖然媽媽不在了，但你要記得，男人的肩膀是用來扛責任的，也別忘了，\n累了的時候，家永遠是你的避風港。\n媽媽希望你開開心心地過每一天，這樣我才會放心。\n\n女兒啊，從小時候到現在，媽媽看著你一步步長大，\n變成一個堅強又貼心的小寶貝，心裡真的特別欣慰。\n以後媽媽不在了，你要記得不管遇到什麼，家人永遠是你最好的依靠。\n媽媽只希望你過得幸福，開心地過好每一天，這就是對我最大的心願。"
    }
  ],
  farewell: [
    {
      id: 1,
      title: "範例一",
      content: "各位親愛的家人、朋友們，\n\n今天，我用另一種方式，來和你們說一聲感謝，也說一聲再見。我的一生，因為有你們的陪伴，變得那麼熱鬧、那麼有趣。你們的笑聲、打氣、和不離不棄的支持，讓我的每一天都充滿了色彩。謝謝你們在我身邊，成就了這樣一個豐盛而精彩的旅程。\n\n每一次和朋友的聚會，每一場和家人的爭吵後的和好，每一個你們給我的擁抱和鼓勵，都是我生命中最珍貴的回憶。這些點點滴滴，我都已經好好地收藏起來，帶著它們，繼續走向屬於我的下一段旅程。\n\n我知道我的離開會讓你們感到難過，但我真的希望，這段悲傷不會停留太久。請記得的，是那些我們一起笑到眼淚都掉下來的瞬間，是那些我們一起瘋狂、一起冒險的時光。因為這些，才是真正屬於我們的故事。\n\n(家人1)、(家人2)、(家人3)、(親密關係1)、(摯友)、(摯友2)，我會永遠感激你們無條件的愛與支持，讓我成為現在的我。親愛的朋友們，謝謝你們讓我的人生充滿驚喜與溫暖。\n\n未來，當你們望向藍天白雲，或在某個夜晚看到閃亮的星星，請記得，那是我在對你們微笑，在說：「嘿！我在這裡，陪著你們呢！」\n\n最後，請讓我們用一個笑容，告別今天的眼淚。我走得很快樂，因為有你們的愛，讓我的人生毫無遺憾。而你們，才是我生命中最重要的寶藏。\n\n謝謝大家，珍重，再見。\n\n帶著滿滿愛與笑容的，"
    },
    {
      id: 2,
      title: "範例二",
      content: "感謝大家在我的人生中，帶來那麼多的歡笑和陪伴。\n你們的關心與支持，讓我的生活更加豐富。\n我雖然離開了，但你們的友誼和愛，我都帶走了，永存於心。\n\n最後，請大家不要為我難過太久。我希望你們記得的，是我笑著的模樣，\n記得那些屬於我們的快樂時光。再未來的日子裡，每當你們回憶起時，\n你們臉上都是帶著笑容，因為我曾經是你們生命的一部分，\n而你們，也讓我的人生圓滿無憾。\n\n謝謝大家，珍重，再見。"
    }
  ]
};

const DemandForm = (): JSX.Element => {
  const navigate = useNavigate();
  const [formType, setFormType] = useState<string>("首次填寫");
  const [publicScript, setPublicScript] = useState<string>("");
  const [familyScript, setFamilyScript] = useState<string>("");
  const [farewellScript, setFarewellScript] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const calculateCost = (scriptLength: number): [number, number] => {
    const sections = Math.ceil(scriptLength / 240);
    const baseLow = 25000;
    const baseHigh = 30000;
    return [sections * baseLow, sections * baseHigh];
  };

  const handleCostCalculation = () => {
    setIsModalVisible(true);
  };

  const familyCost = calculateCost(familyScript.length);
  const publicCost = calculateCost(publicScript.length);
  const farewellCost = calculateCost(farewellScript.length);
  const totalCostLow = familyCost[0] + publicCost[0] + farewellCost[0];
  const totalCostHigh = familyCost[1] + publicCost[1] + farewellCost[1];

  const handleSubmit = () => {
    message.success('提交成功');
    navigate('/');
  };

  const handleExampleClick = (type: keyof ExampleList, exampleId: number) => {
    const example = exampleList[type].find((item: ExampleItem) => item.id === exampleId);
    if (!example) return;

    switch (type) {
      case 'public':
        setPublicScript(example.content);
        break;
      case 'family':
        setFamilyScript(example.content);
        break;
      case 'farewell':
        setFarewellScript(example.content);
        break;
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <Title className="!text-[22px]" level={3} style={{ textAlign: "center" }}>
        形象建立資訊填寫
      </Title>

      <div>
        <div style={{ backgroundColor: "#ffffffcc", padding: "20px" }}>
          <Title className="!text-[20px]" level={4}>
            需求單資訊
          </Title>
          <Row gutter={[16, 16]} className="flex flex-col gap-4 p-4">
            <Col>
              <Radio.Group
                defaultValue="首次填寫"
                onChange={(e) => setFormType(e.target.value)}
              >
                <Radio className="!text-[15px] !bg-white [&_.ant-radio-checked_.ant-radio-inner]:!border-[#FFB81F] [&_.ant-radio-checked_.ant-radio-inner]:!bg-[#FFB81F]" value="首次填寫">
                  首次填寫
                </Radio>
                <Radio className="!text-[15px] !bg-white [&_.ant-radio-checked_.ant-radio-inner]:!border-[#FFB81F] [&_.ant-radio-checked_.ant-radio-inner]:!bg-[#FFB81F]" value="重複編輯">
                  重複編輯（擇一）
                </Radio>
              </Radio.Group>
            </Col>
            <Col span={24} className="flex flex-col ">
              <div className="flex flex-col gap-2">
                <Input
                  placeholder={formType === "首次填寫" ? "系統將自動產生編號" : "請輸入需求單編號"}
                  disabled={formType === "首次填寫"}
                  className={formType === "首次填寫" ? "!bg-[#F5F5F5] !h-[40px] !rounded-[4px]" : "!h-[40px] !rounded-[4px]"}
                />
                {formType === "重複編輯" && (
                  <>
                    <Input
                      placeholder="請輸入親友姓名"
                      className="!h-[40px] !rounded-[4px]"
                    />
                    <Button className="!h-[40px] !rounded-[4px]">
                      查詢
                    </Button>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div>
        <div style={{ backgroundColor: "#ffffffcc", padding: "20px" }}>
          <Title level={4} className="!text-[20px]">基本資訊</Title>
          <Row gutter={[24, 24]} className="p-4">
            <Col xs={12} sm={12} md={8} lg={8}>
              <div className="flex flex-col gap-2">
                <Text className="!text-[15px]">親友姓名</Text>
                <Input
                  placeholder="請輸入親友姓名"
                  className="!h-[40px] !rounded-[4px]"
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <div className="flex flex-col gap-2">
                <Text className="!text-[15px]">親友出生日期</Text>
                <DatePicker
                  style={{ width: "100%", height: "40px" }}
                  placeholder="請選擇日期"
                  suffixIcon={<CalendarIcon />}
                  className="!rounded-[4px]"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <div className="flex flex-col gap-2">
                <Text className="!text-[15px]">親友性別</Text>
                <Radio.Group defaultValue="女" className="flex gap-8">
                  <Radio value="男" className="!text-[15px]">男</Radio>
                  <Radio value="女" className="!text-[15px]">女</Radio>
                </Radio.Group>
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <div className="flex flex-col gap-2">
                <Text className="!text-[15px]">家屬姓名（聯絡人）</Text>
                <Input
                  placeholder="請輸入家屬姓名"
                  className="!h-[40px] !rounded-[4px]"
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <div className="flex flex-col gap-2">
                <Text className="!text-[15px]">家屬聯絡電話</Text>
                <Input
                  placeholder="請輸入電話號碼"
                  className="!h-[40px] !rounded-[4px]"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <div className="flex flex-col gap-2">
                <Text className="!text-[15px]">家屬聯絡email</Text>
                <Input
                  placeholder="請輸入email"
                  className="!h-[40px] !rounded-[4px]"
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <div className="flex flex-col gap-2">
                <Text className="!text-[15px]">禮儀業者</Text>
                <Input
                  placeholder="恆天人本"
                  disabled
                  className="!h-[40px] !rounded-[4px] !bg-[#F5F5F5]"
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <div className="flex flex-col gap-2">
                <Text className="!text-[15px]">出殯日期</Text>
                <DatePicker
                  style={{ width: "100%", height: "40px" }}
                  placeholder="請選擇日期"
                  suffixIcon={<CalendarIcon />}
                  className="!rounded-[4px]"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <div className="flex flex-col gap-2">
                <Text className="!text-[15px]">訃聞</Text>
                <Upload className="[&_.ant-upload-wrapper]:!w-full [&_.ant-upload]:!w-full">
                  <UploadButton text="上傳訃聞照片" />
                </Upload>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div style={{ backgroundColor: "#ffffffcc", padding: "20px" }}>
        <Title level={4} className="!text-[20px]">親友影片製作</Title>

        <Row gutter={[16, 16]} className="mt-4">
          <Col span={24}>
            <Text className="!text-[15px] font-medium flex items-center gap-2">
              <SoundIcon />
              聲音製作
            </Text>
          </Col>

          <Col span={24} className="flex flex-col gap-2">
            <Text className="!text-[15px]">親友聲音</Text>
            <Text type="danger" className="!text-[13px] !text-[#FF0000]">
              ※ 提醒您 聲音檔案不可有太多雜訊、僅可以是1人在說話、音檔說話長度需超過30秒
            </Text>
            <Upload className="[&_.ant-upload-wrapper]:!w-full [&_.ant-upload]:!w-full">
              <UploadButton text="上傳音檔" />
            </Upload>
          </Col>

          <Col span={24} className="flex flex-col gap-2">
            <Text className="!text-[15px]">朗讀語音</Text>
            <Text type="danger" className="!text-[13px] !text-[#FF0000]">
              ※ 盡可能使用需求台詞模仿親友的語氣、語調
            </Text>
            <Radio.Group defaultValue="上傳音檔" className="flex gap-8 mb-2">
              <Radio value="上傳音檔" className="!text-[15px]">上傳音檔</Radio>
              <Radio value="一鍵錄音" className="!text-[15px]">一鍵錄音</Radio>
            </Radio.Group>
            <Upload className="[&_.ant-upload-wrapper]:!w-full [&_.ant-upload]:!w-full">
              <UploadButton text="上傳音檔" />
            </Upload>
          </Col>
        </Row>

        <Divider className="!my-6" />

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Text className="!text-[15px] font-medium flex items-center gap-2">
              <VideoIcon />
              影像製作
            </Text>
          </Col>

          <Col span={24} className="mt-2">
            <Text className="!text-[15px]">公祭</Text>
          </Col>

          <Col span={24} className="flex flex-col gap-2">
            <Text className="!text-[15px]">台詞</Text>
            <div className="flex gap-2 mb-2">
              <Button 
                className="!h-[32px] !rounded-[4px]"
                onClick={() => handleExampleClick('public', 1)}
              >
                範例一
              </Button>
              <Button 
                className="!h-[32px] !rounded-[4px]"
                onClick={() => handleExampleClick('public', 2)}
              >
                範例二
              </Button>
            </div>
            <Input.TextArea
              placeholder="請輸入台詞"
              rows={4}
              showCount
              maxLength={10000}
              className="!rounded-[4px]"
              value={publicScript}
              onChange={(e) => setPublicScript(e.target.value)}
            />
          </Col>

          <Col span={24} className="flex flex-col gap-2">
            <Text className="!text-[15px]">照片</Text>
            <Text type="danger" className="!text-[13px] !text-[#FF0000]">
              ※ 提醒您 圖片解析度需達1080P以上、需為單獨照片、需為正面照片－
            </Text>
            <Upload className="[&_.ant-upload-wrapper]:!w-full [&_.ant-upload]:!w-full">
              <UploadButton text="上傳圖片" />
            </Upload>
          </Col>

          <Col span={24} className="flex flex-col gap-2">
            <Text className="!text-[15px]">輸出影片格式</Text>
            <Radio.Group defaultValue="直式" className="flex gap-8">
              <Radio value="直式" className="!text-[15px]">直式</Radio>
              <Radio value="橫式" className="!text-[15px]">橫式</Radio>
            </Radio.Group>
          </Col>
        </Row>

        <Divider className="!my-6" />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Text className="!text-[15px]">家祭</Text>
          </Col>
          <Col span={24} className="flex flex-col gap-2">
            <Text className="!text-[15px]">台詞</Text>
            <div className="flex gap-2 mb-2">
              <Button 
                className="!h-[32px] !rounded-[4px]"
                onClick={() => handleExampleClick('family', 1)}
              >
                範例一
              </Button>
              <Button 
                className="!h-[32px] !rounded-[4px]"
                onClick={() => handleExampleClick('family', 2)}
              >
                範例二
              </Button>
            </div>
            <Input.TextArea
              placeholder="請輸入台詞"
              rows={4}
              showCount
              maxLength={10000}
              className="!rounded-[4px]"
              value={familyScript}
              onChange={(e) => setFamilyScript(e.target.value)}
            />
          </Col>
          <Col span={24} className="flex flex-col gap-2">
            <Text className="!text-[15px]">照片</Text>
            <Text type="danger" className="!text-[13px] !text-[#FF0000]">
              ※ 提醒您 圖片解析度需達1080P以上、需為單獨照片、需為正面照片
            </Text>
            <Upload className="[&_.ant-upload-wrapper]:!w-full [&_.ant-upload]:!w-full">
              <UploadButton text="上傳圖片" />
            </Upload>
          </Col>
          <Col span={24} className="flex flex-col gap-2">
            <Text className="!text-[15px]">輸出影片格式</Text>
            <Radio.Group defaultValue="直式" className="flex gap-8">
              <Radio value="直式" className="!text-[15px]">直式</Radio>
              <Radio value="橫式" className="!text-[15px]">橫式</Radio>
            </Radio.Group>
          </Col>
        </Row>

        <Divider className="!my-6" />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Text className="!text-[15px]">遠行</Text>
          </Col>
          <Col span={24} className="flex flex-col gap-2">
            <Text className="!text-[15px]">台詞</Text>
            <div className="flex gap-2 mb-2">
              <Button 
                className="!h-[32px] !rounded-[4px]"
                onClick={() => handleExampleClick('farewell', 1)}
              >
                範例一
              </Button>
              <Button 
                className="!h-[32px] !rounded-[4px]"
                onClick={() => handleExampleClick('farewell', 2)}
              >
                範例二
              </Button>
            </div>
            <Input.TextArea
              placeholder="請輸入台詞"
              rows={4}
              showCount
              maxLength={10000}
              className="!rounded-[4px]"
              value={farewellScript}
              onChange={(e) => setFarewellScript(e.target.value)}
            />
          </Col>
          <Col span={24} className="flex flex-col gap-2">
            <Text className="!text-[15px]">照片</Text>
            <Text type="danger" className="!text-[13px] !text-[#FF0000]">
              ※ 提醒您 圖片解析度需達1080P以上、需為單獨照片、需為正面照片
            </Text>
            <Upload className="[&_.ant-upload-wrapper]:!w-full [&_.ant-upload]:!w-full">
              <UploadButton text="上傳圖片" />
            </Upload>
          </Col>
          <Col span={24} className="flex flex-col gap-2">
            <Text className="!text-[15px]">輸出影片格式</Text>
            <Radio.Group defaultValue="直式" className="flex gap-8">
              <Radio value="直式" className="!text-[15px]">直式</Radio>
              <Radio value="橫式" className="!text-[15px]">橫式</Radio>
            </Radio.Group>
          </Col>
        </Row>
      </div>

      <div style={{ backgroundColor: "#ffffffcc", padding: "20px" }}>
        <Title level={4} className="!text-[20px]">親友動作製作</Title>
        <Row gutter={[16, 24]} className="mt-4">
          <Col span={24} className="flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <Text className="!text-[15px]">親友照片1</Text>
              <Checkbox className="!text-[15px]">同上</Checkbox>
            </div>
            <Text type="danger" className="!text-[13px] !text-[#FF0000]">
              ※ 提醒您 圖片解析度需達1080P以上、需為單獨照片、需為正面照片
            </Text>
            <Upload className="[&_.ant-upload-wrapper]:!w-full [&_.ant-upload]:!w-full">
              <UploadButton text="上傳圖片" />
            </Upload>
          </Col>

          <Col span={24}>
            <div className="flex flex-col gap-2">
              <Text className="!text-[15px]">動作呈現1</Text>
              <Select
                placeholder="請選擇"
                className="!w-full md:!w-[400px]"
                suffixIcon={<DropdownIcon />}
              />
            </div>
          </Col>

          <Col span={24} className="flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <Text className="!text-[15px]">親友照片2</Text>
              <Checkbox className="!text-[15px]">同上</Checkbox>
            </div>
            <Text type="danger" className="!text-[13px] !text-[#FF0000]">
              ※ 提醒您 圖片解析度需達1080P以上、需為單獨照片、需為正面照片
            </Text>
            <Upload className="[&_.ant-upload-wrapper]:!w-full [&_.ant-upload]:!w-full">
              <UploadButton text="上傳圖片" />
            </Upload>
          </Col>

          <Col span={24}>
            <div className="flex flex-col gap-2">
              <Text className="!text-[15px]">動作呈現2</Text>
              <Select
                placeholder="其他"
                className="!w-full md:!w-[400px]"
                suffixIcon={<DropdownIcon />}
              />
              <Input.TextArea
                placeholder="請輸入其他動作內容指示"
                rows={2}
                className="!w-full md:!w-[400px] !mt-2"
              />
            </div>
          </Col>

          <Col span={24}>
            <Row gutter={[24, 16]}>
              <Col span={24} md={8}>
                <div className="flex flex-col gap-2">
                  <Text className="!text-[15px]">輸入提示詞</Text>
                  <Input
                    placeholder="請輸入提示詞"
                    className="!h-[40px] !rounded-[4px]"
                  />
                </div>
              </Col>
              <Col span={24} md={8}>
                <div className="flex flex-col gap-2">
                  <Text className="!text-[15px] whitespace-nowrap">輸入反向提示詞</Text>
                  <Input
                    placeholder="請輸入反向提示詞"
                    className="!h-[40px] !rounded-[4px]"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div style={{ backgroundColor: "#ffffffcc", padding: "20px" }}>
        <Title level={4} className="!text-[20px]">親友形象問卷</Title>
        <Row gutter={[16, 16]} className="mt-4">
          <Col span={24} className="flex justify-between items-center">
            <Text className="!text-[15px]">
              請前往表單側寫親友個性、喜好等問題，讓我們可以製作更加貼切的形象內容
            </Text>
            <Button
              type="primary"
              className="!h-[40px] !rounded-[4px] !bg-[#FFB81F] !text-[15px] hover:!bg-[#FFB81F]/80"
              onClick={() => window.open('https://docs.google.com/forms/d/1xOUVhsXUl7P5_-crpuHvsPOsPQMSs_y_Oyp_RVxi8Xs/viewform?pli=1&pli=1&edit_requested=true', '_blank')}
            >
              前往問卷進行填寫
            </Button>
          </Col>
        </Row>
      </div>

      <div style={{ backgroundColor: "#ffffffcc", padding: "20px" }}>
        <Title level={4}>備註</Title>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Input.TextArea placeholder="請輸入備註" rows={4} showCount maxLength={10000} />
          </Col>
        </Row>
      </div>

      <Row justify="end" gutter={[16, 16]}>
        <Col>
          <Button
            onClick={handleCostCalculation}
          >
            費用試算</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={handleSubmit}>提交需求</Button>
        </Col>
      </Row>

      <Modal
        title="費用試算"
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="ok" type="primary" onClick={() => setIsModalVisible(false)}>
            確定
          </Button>
        ]}
      >
        <div className="flex flex-col gap-4">
          <div>家祭：{familyCost[0].toLocaleString()}-{familyCost[1].toLocaleString()}元</div>
          <div>公祭：{publicCost[0].toLocaleString()}-{publicCost[1].toLocaleString()}元</div>
          <div>遠行：{farewellCost[0].toLocaleString()}-{farewellCost[1].toLocaleString()}元</div>
          <Divider className="!my-2" />
          <div>共：{totalCostLow.toLocaleString()}-{totalCostHigh.toLocaleString()}元</div>
          <div className="text-red-500 text-sm mt-4">※ 優惠請洽禮儀業者</div>
        </div>
      </Modal>
    </div>
  );
};

export default DemandForm;

import React from 'react';
import { Tabs } from 'antd';
import Tab1 from '../assets/bg-1.jpg';

const ServicePlan: React.FC = () => {
  return (
    <div className="mx-auto pb-8">
      <Tabs
        defaultActiveKey="1"
        centered
        items={[
          {
            key: '1',
            label: <span style={{ fontSize: '1rem' }}>溫情重聚</span>,
            children: (
              <div className="relative">
                <img src={Tab1} alt="方案" className="w-full" />
                <div className="absolute left-1/2 top-0 md:top-10 -translate-x-1/2 flex flex-col items-start text-black w-[90%] md:w-[75%] lg:w-[55%] p-4 rounded-lg">
                  <div className="font-['Inter'] text-[16px] md:text-[18px] lg:text-[20px] font-bold leading-[24px] md:leading-[40px] lg:leading-[50px] tracking-[0.2em] md:tracking-[0.3em] lg:tracking-[0.4em]">
                    價格請洽禮儀業者
                  </div>
                  <div className="font-['Inter'] text-[14px] md:text-[16px] lg:text-[20px] leading-[24px] md:leading-[40px] lg:leading-[50px] tracking-[0.2em] md:tracking-[0.3em] lg:tracking-[0.4em]">
                    讓親友感受到溫暖的重逢，重溫美好回憶。
                  </div>
                </div>
              </div>
            ),
          },
          {
            key: '2',
            label: <span style={{ fontSize: '1rem' }}>AI永恆記憶</span>,
            children: (
              <div className="relative">
                <img src={Tab1} alt="方案" className="w-full" />
                <div className="absolute left-1/2 top-0 md:top-10 -translate-x-1/2 flex flex-col items-start text-black w-[90%] md:w-[75%] lg:w-[55%] p-4 rounded-lg">
                  <div className="font-['Inter'] text-[16px] md:text-[18px] lg:text-[20px] font-bold leading-[24px] tracking-[0.2em]">
                    服務內容
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-5 gap-y-2 md:gap-y-4 md:gap-x-8 mt-2 md:mt-6">
                    <div className="md:col-span-2">
                      <div className="text-[14px] md:text-[16px] leading-[20px] tracking-[0.15em] md:tracking-[0.2em] mb-1 md:mb-4">
                        ● AI外貌、聲音重塑
                      </div>
                      <div className="text-[14px] md:text-[16px] leading-[20px] tracking-[0.15em] md:tracking-[0.2em] mb-1 md:mb-4">
                        ● 溫情談話
                      </div>
                      <div className="text-[14px] md:text-[16px] leading-[20px] tracking-[0.15em] md:tracking-[0.2em]">
                        ● 來賓答謝
                      </div>
                    </div>
                    <div className="md:col-span-3">
                      <div className="text-[14px] md:text-[16px] leading-[20px] tracking-[0.15em] md:tracking-[0.2em] mb-1 md:mb-4">
                        ● 遠行告別
                      </div>
                      <div className="text-[14px] md:text-[16px] leading-[20px] tracking-[0.15em] md:tracking-[0.2em] mb-1 md:mb-4">
                        ● 3D立體投影
                      </div>
                      <div className="text-[14px] md:text-[16px] leading-[20px] tracking-[0.15em] md:tracking-[0.2em]">
                        ● 動畫音樂設置、現場設置、現場技術人員
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default ServicePlan; 
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import ServicePlan from './components/ServicePlan';
import Tab1 from './assets/溫情加值服務.png';
import { Form, Input, DatePicker, Radio, Select, Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import callApi from './utils/callApi';
import Notice from './assets/溫馨提醒.jpg';
import moment from 'moment';
import DemandForm from './DemandForm';
interface FormValues {
  name: string;                 // 親友姓名
  birthDate: moment.Moment;     // 親友出生日期
  gender: 'male' | 'female';    // 親友性別
  phone: string;               // 家屬聯絡電話
  funeralDate: moment.Moment;   // 出殯日期
  guarantor: string;           // 禮儀業者
  publicCeremony: string;      // 公祭台詞
  familyCeremony: string;      // 家祭台詞
  farewell: string;            // 遠行台詞
  action: 'sit' | 'stand' | 'turn' | 'bow' | 'worship' | 'other';  // 動作呈現
}

// 首先定義新的介面
interface Information {
  basic: {
    name: string;
    birthday: string;
    gender: string;
    phone: string;
    date: string;
    funeral: string;
  };
  ceremony: {
    public_sacrifice: string;
    family_sacrifice: string;
    travel: string;
    action: string;
  };
  upload: {
    image: string;
    voice: string;
  };
}

interface SubmitData {
  name: string;
  description: string;
  information: Information;
}

const Combo: React.FC = () => {
  
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [imageFiles, setImageFiles] = useState<any[]>([]);
  const [audioFiles, setAudioFiles] = useState<any[]>([]);

  // breadcrumb navigation
  const BreadcrumbNav = () => (
    <div className="flex flex-col space-y-2 mb-4">
      <div className="flex items-center">
        <span 
          className="text-[20px] cursor-pointer hover:text-blue-600" 
          onClick={() => navigate('/')}
        >
          返回
        </span>
        <span className="mx-2 text-[20px]">/</span>
        <span className="text-[20px]">服務方案</span>
      </div>
    </div>
  );


  const onFinish = async (values: FormValues) => {
    try {
      // 構建基本資料
      const submitData: SubmitData = {
        name: values.name,
        description: values.guarantor,
        information: {
          basic: {
            name: values.name,
            birthday: values.birthDate.format('YYYY-MM-DD'),
            gender: values.gender,
            phone: values.phone,
            date: values.funeralDate.format('YYYY-MM-DD'),
            funeral: values.guarantor
          },
          ceremony: {
            public_sacrifice: values.publicCeremony,
            family_sacrifice: values.familyCeremony,
            travel: values.farewell,
            action: values.action
          },
          upload: {
            image: "",
            voice: ""
          }
        }
      };

      const group_id = "6fbba4f2-d965-452a-8ec4-75e780317b25";
      
      // 先創建項目
      const response = await callApi.post(`/group/${group_id}`, submitData);
      const item_id = response.data.id;

      // 上傳圖片
      if (imageFiles.length > 0) {
        for (const file of imageFiles) {
          const formData = new FormData();
          formData.append('file', file.originFileObj);
          
          try {
            await callApi({
              method: 'post',
              url: `/item/${item_id}`,
              data: formData,
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
          } catch (error) {
            console.error('圖片上傳失敗：', error);
            message.error(`圖片 ${file.name} 上傳失敗`);
          }
        }
      }

      // 上傳音檔
      if (audioFiles.length > 0) {
        for (const file of audioFiles) {
          const formData = new FormData();
          formData.append('file', file.originFileObj);
          
          try {
            await callApi({
              method: 'post',
              url: `/item/${item_id}`,
              data: formData,
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
          } catch (error) {
            console.error('音檔上傳失敗：', error);
            message.error(`音檔 ${file.name} 上傳失敗`);
          }
        }
      }

      message.success('表單提交成功！');
      form.resetFields();
      setImageFiles([]);
      setAudioFiles([]);

    } catch (error) {
      message.error('提交失敗，請稍後重試');
      console.error('提交失敗：', error);
    }
  };

  return (
    <>
      <div className='flex flex-col gap-4 p-4'>
        <BreadcrumbNav />
        <ServicePlan />
        <img src={Tab1} alt="溫情加值" className="w-full" />
      </div>
    <div className="flex flex-col gap-4  mx-auto bg-[#F6F4EF] p-4 mb-4 pt-[30px] relative">
      <DemandForm />
      <img src={Notice} alt="溫馨提醒" className="w-full" />
    </div>
    </>
  );
};

export default Combo;
